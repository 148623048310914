<template>
  <div class="main">
    <div class="list">
      <div class="item" v-for="item in list" :key="item.id" @click="handleClickItem(item)">
        <div class="top">
          <div class="date">{{ item.health_data.input_time }}</div>
          <div class="number">{{ item.xm }}</div>
        </div>
        <div class="core">
          <div class="tags">
            <div class="tag">
              <span class="tagname">心率</span>
              <van-tag :type="item.health_data.xl_color == 'red' ? 'danger' : 'success'" size="medium">
                {{
                  item.health_data.xl || '-'
                }}
              </van-tag>
            </div>
            <div class="tag">
              <span class="tagname">血压</span>
              <van-tag :type="item.health_data.xy_color == 'red' ? 'danger' : 'success'" size="medium">{{
                item.health_data.xueyagao ||
                '-'
              }}/{{ item.health_data.xueyadi || '-' }}</van-tag>
            </div>
            <div class="tag">
              <span class="tagname">体温</span>
              <van-tag :type="item.health_data.tw_color == 'red' ? 'danger' : 'success'" size="medium">{{
                item.health_data.tw || '-'
              }}</van-tag>
            </div>
            <div class="tag">
              <span class="tagname">血氧</span>
              <van-tag :type="item.health_data.xueyang_color == 'red' ? 'danger' : 'success'" size="medium">{{
                item.health_data.xueyang || '-'
              }}</van-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <van-overlay :lock-scroll="false" :show="showDetail" @click="closeOverlay()">
    <div class="overlayWrap" @click.stop>
      <div class="overlayTop">
        <img src="@/assets/img/detail-close.png" @click="closeOverlay" alt="" class="top-img">
      </div>
      <div class="overlayHead">
        <div class="btn" @click="handlePrevDay()">
          <van-icon name="arrow-left" />
        </div>
        <div class="dateInner">{{ currentRq }}</div>
        <div class="btn" @click="handleNextDay()">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="overlayDesc" v-if="currentDetail">
        <van-row>
          <van-col span="12">
            <div class="descItem">
              <div class="descTitle">睡眠时长</div>
              <div class="descValue">{{ getTimeOfMin(currentDetail.smsc) }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="descItem">
              <div class="descTitle">清醒次数</div>
              <div class="descValue">{{ currentDetail.qxcs }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="descItem">
              <div class="descTitle">入睡时间</div>
              <div class="descValue">{{ currentDetail?.rssj?.substring(0, 16) }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="descItem">
              <div class="descTitle">起床时间</div>
              <div class="descValue">{{ currentDetail?.qcsj?.substring(0, 16) }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="descItem">
              <div class="descTitle">深睡</div>
              <div class="descValue">{{ getTimeOfMin(currentDetail.sssc) }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="descItem">
              <div class="descTitle">浅睡</div>
              <div class="descValue">{{ getTimeOfMin(currentDetail.qssj) }}</div>
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="overlayTable">
        <div class="thead">
          <div class="tr">
            <div class="td">时间</div>
            <div class="td">心率</div>
            <div class="td">血压</div>
            <div class="td">体温</div>
            <div class="td">血氧</div>
            <div class="td">呼吸</div>
          </div>
        </div>
        <div class="tbody" style="pointer-events: all; overflow:auto;max-height:326px;">
          <div class="tr" v-for="item in currentJkList" :key="item.id">
            <div class="td">{{ item.sj }}</div>
            <div class="td">{{ item.xl }}</div>
            <div class="td">{{ item.xy }}</div>
            <div class="td">{{ item.tw }}</div>
            <div class="td">{{ item.xueyang }}</div>
            <div class="td">{{ item.hx }}</div>
          </div>

        </div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';

export default {
  data() {
    return {
      showDetail: false,
      list: [],
      currentSjhm: '',
      currentRq: '',
      currentTime: null,
      loading: false,
      currentDetail: null,
      currentJkList: [],
      store: null,
    }
  },
  created() {
  },
  async mounted() {
    // console.log(this.store);
    await this.getStore();
    if (this.store) {
      this.get_huiyuan_zhibiao(this.store.id)
    }
  },
  watch: {
    $route(route) {
      console.log(route);
      this.get_huiyuan_zhibiao(this.store?.id, route.query.search  || '')
    }
  },
  methods: {
    async getStore() {
      let { data: res } = await axios.get("/api/api/get_guanli_mendian", {
        params: {
          sjhm: this.$route.query.sjhm
        }
      });
      this.store = res.data?.[0] || null;
    },
    async handlePrevDay() {
      if (this.loading) return;
      this.loading = true;
      this.currentTime = this.currentTime?.subtract(1, 'day');
      this.currentRq = this.currentTime.format('MM月DD日')
      await this.getCurrentDetail();
      await this.getCurrentJkList();
      this.loading = false;
    },
    async handleNextDay() {
      if (this.loading) return;
      this.loading = true;
      this.currentTime = this.currentTime?.add(1, 'day');
      this.currentRq = this.currentTime.format('MM月DD日');
      await this.getCurrentDetail();
      await this.getCurrentJkList();
      this.loading = false;
    },
    async getCurrentDetail() {
      let { data: res } = await axios.get('/api/api/get_one_sleep_data', {
        params: {
          rq: this.currentTime?.subtract(1, 'day').format('YYYY-MM-DD') || '',
          sjhm: this.currentSjhm
        }
      });
      this.currentDetail = res.data || null
    },
    async getCurrentJkList() {
      let { data: res } = await axios.get('/api/api/get_health_page_data', {
        params: {
          rq: this.currentTime?.format('YYYY-MM-DD') || '',
          sjhm: this.currentSjhm
        }
      });
      this.currentJkList = res.data;
      // this.currentJkList = res.data.filter(f => f.rq == this.currentTime?.format('YYYY-MM-DD')) || null
      // console.log(this.currentJkList);
    },
    closeOverlay() {
      this.showDetail = false;
      setTimeout(() => {
        this.currentRq = '';
        this.currentSjhm = '';
        this.currentTime = null;
        this.currentDetail = null;
        this.currentJkList = [];
      }, 500)
    },
    handleClickItem(item) {
      this.showDetail = true;
      this.currentSjhm = item.sjhm;
      this.currentTime = dayjs()
      this.currentRq = this.currentTime.format('MM月DD日')
      this.getCurrentDetail();
      this.getCurrentJkList();
    },
    async get_huiyuan_zhibiao(id, sjhm = '') {
      let { data: res } = await axios.get('/api/api/get_huiyuan_zhibiao', {
        params: {
          store_id: id,
          sjhm: sjhm
        }
      });
      this.list = res.data || []
    },
    getTimeOfMin(str) {
      if (!str) return '';
      return Math.floor(str / 60) + '小时' + (str % 60) + '分钟'
    }
  }
}
</script>
<style lang="less" scoped="scoped">
.main {
  flex: 1;
  width: 100%;
  overflow: auto;

  .list {
    margin-top: 16px;

    .item {
      margin: 12px;
      background: #fff;
      padding: 12px;
      border-radius: 5px;

      &:active {
        opacity: .8;
      }

      .top {
        display: flex;
        justify-content: space-between;

        .date {
          font-size: 14px;
          color: #666;
        }

        .number {
          font-size: 16px;

          &.red {
            color: red;
          }

          &.green {
            color: aquamarine;
          }
        }
      }

      .core {
        padding-top: 12px;

        .tags {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .tag {
            display: flex;
            align-items: center;

            .tagname {
              font-size: 12px;
              color: #999;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}

.overlayWrap {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  padding-top: 10px;
  border-radius: 10px;
  padding-bottom: 32px;

  .overlayTop {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .top-img {
      width: 18px;
    }
  }

  .overlayHead {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border-bottom: 1px solid #f5f6f7;

    .btn {
      padding: 0 24px;
      height: 24px;
      display: flex;
      align-items: center;
      color: #666;
    }
  }

  .overlayDesc {
    padding: 6px;

    .descItem {
      // display: flex;
      text-align: center;
      margin: 6px;

      // justify-content: center;
      .descTitle {
        color: #3395a2;
        font-size: 14px;
        margin-bottom: 6px;
      }

      .descValue {
        color: #333;
        font-size: 14px;
      }
    }
  }

  .overlayTable {
    text-align: center;

    .thead {
      color: #fff;

      .tr {
        display: flex;
        height: 32px;
        align-items: center;
        justify-content: space-between;

        background: #3395a2;

        &:nth-child(2n) {
          background: #58ded7;
        }

        .td {
          flex: 1;
          font-size: 12px;
        }
      }
    }

    .tbody {
      color: #333;
      font-size: 16px;

      .tr {
        display: flex;
        height: 32px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f5f6f7;

        &:last-child {
          border: none;
        }

        .td {
          flex: 1;
          font-size: 14px;
        }
      }
    }
  }
}
</style>